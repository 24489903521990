<template>
	<div>
		<ITCSpinner :loaded="loaded">
			<div v-if="streams.length == 0">
				<div class="card-body d-flex flex-column justify-content-center align-items-center">
					<div>
						<span class="svg-icon svg-icon-primary svg-icon-10x">
							<inline-svg src="/media/svg/icons/Media/DVD.svg"></inline-svg>
						</span>
					</div>
					<div class="my-5">
						<h3>You don't have any video streams yet</h3>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-6 col-lg-12" v-for="stream in streams" :key="stream.id">
					<div class="card card-custom card-stretch gutter-b">
						<VideoPlayer v-if="stream.ant_id" :name="stream.stream_name" :antId="stream.ant_id" :jwt="stream.jwt" />
					</div>
				</div>
			</div>
		</ITCSpinner>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
	name: 'Video',
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		VideoPlayer: () => import('@/view/content/widgets/video/VideoPlayer.vue'),
	},
	data() {
		return {
			loaded: false,
			streams: [],
		};
	},
	computed: {
		...mapGetters(['currentUser']),
	},
	methods: {
		getBroadcasts() {
			this.streams.forEach(stream => {
				this.$http.get(`/stream/${stream.ant_id}/live`).then(resp => {
					this.$set(stream, 'data', resp.data);
				});
			});
		},
		showStreamInfo(stream) {
			stream.loadingInfo = true;
			this.$http.get(`/stream/${stream.ant_id}/stream-info`).then(resp => {
				stream.info = resp.data[0];
				stream.loadingInfo = false;
			});
		},
	},
	created() {
		this.loaded = false;
		this.$http.get(`streams/${this.currentUser.username}`).then(resp => {
			this.streams = resp.data;
			// this.streams.forEach(stream => {
			// 	this.$set(stream, 'showInfo', false);
			// 	this.$set(stream, 'info', null);
			// });
			this.loaded = true;
			// this.getBroadcasts();
		});
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Streaming Video' }]);
	},
};
</script>

<style lang="scss" scoped>
.clickable:hover {
	color: $primary-hover;
	cursor: pointer;
}
</style>
